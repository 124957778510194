import React from 'react';
import { FaMousePointer, FaLinkedin, FaGithub } from 'react-icons/fa';

import { Container, Partner, Description, Socials } from './styles';

import dorigo from '../../assets/images/principais/dorigo.png';
import hideki from '../../assets/images/principais/hideki.png';
import catu from '../../assets/images/principais/catu.png';

export default function Partners() {
  return (
    <Container id="Partners">
      <Partner>
        <img src={dorigo} alt="Diego Dorigo" title="Diego Dorigo" />
        <Description>
          <h4>Diego Dorigo</h4>
          <p>FullStack Web Developer & Design</p>
          <Socials>
            <a href="https://dorigo.dev/"><FaMousePointer color="#eee" size={20} /></a>
            <a href="https://www.linkedin.com/in/ddorigo/"><FaLinkedin color="#eee" size={20} /></a>
          </Socials>
        </Description>
      </Partner>
      <Partner>
        <img src={hideki} alt="Sergio Hideki" title="Sergio Hideki" />
        <Description>
          <h4>Sergio Hideki</h4>
          <p>Front-End Developer & Design</p>
          <Socials>
            <a href="https://www.sergiohideki.dev/"><FaMousePointer color="#eee" size={20} /></a>
            <a href="https://www.linkedin.com/in/sergio-hideki-ono-947b066a/"><FaLinkedin color="#eee" size={20} /></a>
            <a href="https://github.com/sergiohideki"><FaGithub color="#eee" size={20} /></a>
          </Socials>
        </Description>
      </Partner>
      <Partner>
        <img src={catu} alt="Rafael Martinosso" title="Rafael Martinosso" />
        <Description>
          <h4>Rafael Martinosso</h4>
          <p>Analista de TI</p>
          <Socials>
            <a href="https://www.linkedin.com/in/rafael-martinosso/"><FaLinkedin color="#eee" size={20} /></a>
          </Socials>
        </Description>
      </Partner>
    </Container>
  );
}
