import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 5% 0 5% 0;
  background: linear-gradient(#36323F, #4F4F4F, #696969);
`;

export const Partner = styled.div`
  border: 2px solid #eee;
  height: 100%;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  transition: box-shadow 0.4s;
  min-height: 350px;
  max-height: 350px;
    img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      max-height: 250px;
      max-width: 200px;
      margin-top: 10%;
      &:hover {
        -webkit-transform: scale(1.02);
        -moz-transform: scale(1.02);
        -o-transform: scale(1.02);
        -ms-transform: scale(1.02);
        transform: scale(1.02);
      }
    }
  h4 {
    color: #04D361;
  }
  p {
    color: #eee;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
`;

export const Socials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5%;

  a {
    padding-right: 15%;
    &:hover {
      -webkit-transform: scale(1.5);
      -moz-transform: scale(1.5);
      -o-transform: scale(1.5);
      -ms-transform: scale(1.5);
      transform: scale(1.5);
    }
    svg {
      &:hover {
        color: #04D361 !important;
      }
    }
  }
`;


