import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  max-height: 550px;
  width: 100%;
  background: linear-gradient(#36323F, #4F4F4F, #696969);
  
  h1 {
    margin: 5% 0 5% 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #eee;
  }

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    align-items: flex-start;
    justify-content: space-around;
    margin-bottom: 5%;

    li {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin: 2%;

      img {
        width: 80px;
        height: 80px;
        flex: 1 0 auto;
        margin: 0 5px 5px 0;

        &:hover {
          -webkit-transform: scale3d(1.5, 1.2, 0.8);
          -moz-transform: scale3d(1.5, 1.2, 0.8);
          -o-transform: scale3d(1.5, 1.2, 0.8);
          -ms-transform: scale3d(1.5, 1.2, 0.8);
          transform: scale3d(1.5, 1.2, 0.8);
        }
      }
    }
  }
`;