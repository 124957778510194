import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  max-height: 400px;
  width: 100%;
  padding: 5% 0 5% 0;
  background: linear-gradient(#36323F, #4F4F4F, #696969);
  flex-wrap: wrap;
`;

export const Habilities = styled.div`
  border: 2px solid #eee;
  height: 80%;
  max-height: 400px;
  width: 30%;
  max-width: 340px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  transition: box-shadow 0.4s;

  &:hover {
    box-shadow: 10px 10px 10px 5px rgba(238, 238, 238, 0.2);
    cursor: context-menu;
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
    -o-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }
    
  img {
    height: 110px;
    width: 110px;
    padding: 15px 0 15px 0;
  }

  h3 {
    color: #04D361;
  }

  p {
    margin: 5% 5% 10% 5%;
    color: #eee;
  }
`;