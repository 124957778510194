import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  background: linear-gradient(#04D361, #0be66d, #07f873);
  flex-wrap: wrap;
`;

export const Infos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 5% 2% 0 2%;
  width: 100%;
  max-width: 575px;
  height: 100%;
  max-height: 500px;
  
  strong {
    font-size: 32px;
    font-weight: bold;
    color: #36323F;
    padding: 5px 5px 25px 5px;
  }

  p {
    color: #36323F;
  }
`;

export const Form = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5% 2% 3% 2%;
  width: 100%;
  max-width: 575px;
  height: 100%;
  max-height: 500px;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    width: 100%;
    height: 100%;

    input {
      background: rgba(54, 50, 63, 0.4);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #36323F;
      margin: 0 0 10px;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
    }

    textarea {
      background: rgba(54, 50, 63, 0.4);
      border: 0;
      border-radius: 4px;
      height: 130px;
      padding: 0 15px;
      color: #36323F;
      margin: 0 0 10px;
      &::placeholder {
        padding-top: 10px;
        color: rgba(255, 255, 255, 0.7);
      }
    }

    button {
      margin: 5px 0 0;
      height: 44px;
      background: #36323F;
      font-weight: bold;
      color: #04D361;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      max-width: 150px;
      &:hover {
        background: ${darken(0.04, '#36323F')};
      }
    }
  }
`;