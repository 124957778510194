import React from 'react';

import { Container, Habilities } from './styles';

import developer from '../../assets/images/principais/developer-light.svg';
import design from '../../assets/images/principais/design-light.svg';
import experience from '../../assets/images/principais/experience-light.svg';

export default function Whoiam() {
  return (
    <Container id="Whoiam">
      <Habilities>
        <img src={developer} alt="Desenvolvimento" />
        <h3>Desenvolvimento</h3>
        <p>
          Realizamos o desenvolvimento de Sites, Sistemas Web e Aplicativos Mobile, 
          utilizando as melhores tecnologias do mercado atual buscando sempre estar em constante evolução.
        </p>
      </Habilities>
      <Habilities>
        <img src={design} alt="Criatividade" />
        <h3>Criatividade</h3>
        <p>
          Criatividade na maneira de projetar e implementação de cada projeto, 
          tirar as ideias do papel e atender às necessidades de nossos clientes.          
        </p>
      </Habilities>
      <Habilities>
        <img src={experience} alt="Experience" />
        <h3>Experiência</h3>
        <p>
          Experiência adquirida trabalhando em projetos, e cursos complementares para 
          aprimorar os designes patterns mais avançados do mercado hoje.
        </p>
      </Habilities>
    </Container>
  );
}
