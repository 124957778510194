import React from 'react';

import { Container, Form, Infos } from './styles';

export default function Contact() {
  return (
    <Container id="Contact">
      <Infos>
        <strong>
          Possui uma ideia ?<br />Vamos colocar em prática !
        </strong>
        <p> 
          Mande-nos uma mensagem, vamos conversar e analisar qual a melhor tecnologia para tirar sua ideia do papel
          e a tornar realidade.
        </p>
      </Infos>
      <Form>
        <form name="contact" action="/" method="POST" data-netlify="true" netlify-honeypot="bot-field">
          <input type="text" placeholder="Digite seu nome" name="name" />
          <input type="email" placeholder="Digite seu email" name="email" />
          <textarea name="message" placeholder="Digite sua mensagem" rows="5"></textarea>
          <button type="submit">Enviar</button>
        </form>
      </Form>
    </Container>
  );
}
