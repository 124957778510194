import React from 'react';

import {  Container } from './styles';

import profile from '../../assets/images/principais/Profile.jpeg';

export default function History() {
  return (
    <Container>
      <aside>
        <img src={profile} alt="Gabriel Mendes"/>
      </aside>
      <aside>
        {/* <h1>Historia</h1> */}
        <p>
          Atuando a pouco mais de 1 ano e 6 meses no mercado na tecnologia Angular 2+ em Front-End, estou em busca
          de especializar em toda a Stack JS. Decidindo focar exclusivamente em tecnologia para Back-End sendo NodeJS,
          e Front-End sendo ReactJS e React Native para o Mobile.
        </p>
        <br />
        <p>Engenheiro Agrônomo pela Universidade de Marilia.</p>
        <br />
        <p>Tecnólogo em Analise e Desenvolvimento de Sistemas pelo Centro Universitário de Adamantina.</p>
        <br />
        <p>
          Sempre em buscar de aprimorar os conhecimentos de ferramentas Web/Mobile da atualidade, ultimamente estou realizando
          o treinamento avançado da RocketSeat o Bootcamp nas tecnologias JS (NodeJS, ReactJS e React Native).
        </p>
      </aside>
    </Container>
  );
}
