import React from 'react';

import LogoCompleto from '../../assets/images/principais/logo-completo-branco.png'; 

import {  Container } from './styles';

export default function Head() {
  return (
    <Container id="Head">
      <img src={LogoCompleto} alt="Gabriel Mendes" />
    </Container>
  );
}
