import styled from 'styled-components';
import banner from '../../assets/images/principais/background.png';

export const Container = styled.div`
  background: no-repeat url(${banner});
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100vh;
  width: 100%;

  img {
    width: 65%;
    height: 25%;
  }
`;