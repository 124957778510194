import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 5% 0 5% 0;
  background: linear-gradient(#04D361, #0be66d, #07f873);
  flex-wrap: wrap;

  aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    img {
      width: 50%;
      height: 50%;
      border-radius: 50%;
    }

    h1 {
      color: #36323F;
    }

    p {
      padding: 5px;
      font-size: 18px;
      max-width: 350px;
      max-height: 350px;
      color: #36323F;
    }
  }

`;