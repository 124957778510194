import React from 'react';

import {  Container } from './styles';

import css from '../../assets/images/skills/Css.png';
import docker from '../../assets/images/skills/Docker.png';
import es6 from '../../assets/images/skills/Es6.png';
import git from '../../assets/images/skills/GIT.png';
import html5 from '../../assets/images/skills/html5.png';
import jsBeer from '../../assets/images/skills/JSBeer.png';
import mongoDB from '../../assets/images/skills/MongoDB.png';
import mySql from '../../assets/images/skills/MySQL.png';
import nodejs from '../../assets/images/skills/nodejs.png';
import postgres from '../../assets/images/skills/postgresql.png';
import react from '../../assets/images/skills/react.png';
import reactNative from '../../assets/images/skills/reactNative.png';
import redux from '../../assets/images/skills/redux.png';
import typeScript from '../../assets/images/skills/typeScript.png';
import webpack from '../../assets/images/skills/webpack.png';

const skilling = [
  {
    title: 'HTML5',
    img: html5,
  }, 
  {
    title: 'CSS3',
    img: css,
  }, 
  {
    title: 'JavaScript',
    img: jsBeer,
  },
  {
    title: 'Es6',
    img: es6,
  }, 
  {
    title: 'GIT',
    img: git,
  }, 
  {
    title: 'TypeScript',
    img: typeScript,
  }, 
  {
    title: 'Webpack',
    img: webpack,
  }, 
  {
    title: 'React',
    img: react,
  }, 
  {
    title: 'React Native',
    img: reactNative,
  }, 
  {
    title: 'Redux',
    img: redux,
  }, 
  {
    title: 'NodeJS',
    img: nodejs,
  }, 
  {
    title: 'Docker',
    img: docker,
  }, 
  {
    title: 'MongoDB',
    img: mongoDB,
  }, 
  {
    title: 'MySQL',
    img: mySql,
  }, 
  {
    title: 'PostgreSQL',
    img: postgres,
  }
]

export default function Skills() {
  return (
    <Container id="Skills">
      <h1>Conhecimentos Tecnológicos</h1>
      <ul>
        {skilling.map(skill => (
          <li key={skill.title} title={skill.title}>
            <img src={skill.img} alt={skill.title} />
          </li>
        ))}
      </ul>
    </Container>
  );
}
