import React from 'react';
import SEO from '../../components/Seo';
import Layout from '../../components/Layout';

import Head from '../../components/Head';
import Whoiam from '../../components/Whoiam';
import History from '../../components/History';
import Skills from '../../components/Skills';
// import Portfolio from '../../components/Portfolio';
import Partners from '../../components/Partners';
import Contact from '../../components/Contact';

import { Container } from './styles';

export default function Home() {
  return (
    <Layout>
      <SEO title="Gabriel Mendes" />
      <Container>
        <Head />
        <Whoiam />
        <History />
        <Skills />
        {/* <Portfolio /> */}
        <Partners />
        <Contact />
      </Container>
    </Layout>
  );
}
